<template>
  <div class="pt-1">
    <v-row>
      <v-col cols="12" class="pt-0 pl-0 pr-0">
        <v-container
          id="tblCarriers"
          fluid
          class="pt-0"
          style="max-height: 550px; position: relative; padding-bottom: 0"
        >
          <v-data-table
            id="tblData"
            :headers="headers"
            :items="carriers"
            class="elevation-2"
            calculate-widths
            hide-default-footer
            fixed-header
            :items-per-page="pageSize"
            style="max-height: 550px; overflow-y: scroll"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'Carrier Detail',
                  params: { id: item.id },
                }"
                class="name-link"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:footer>
              <v-page
                :total-row="totalRecord"
                :page-size-menu="[10, 20, 50, 100]"
                class="pt-3 pr-3 pb-3"
                @page-change="readDataFromAPI"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Carriers",
  comments: {},
  props: {
    factoringId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", align: "center" },
        { text: "MC Number", value: "mcNumber", align: "center" },
        { text: "DOT Number", value: "dotNumber", align: "center" },
        { text: "Status", value: "blacklist", align: "center" },
      ],
      carriers: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("factoring", {
      carrierCompanies: "carrierCompanies",
    }),
  },
  watch: {
    carrierCompanies() {
      this.totalRecord = this.carrierCompanies.count
      this.carriers = this.carrierCompanies.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.carriers = []
    this.getFactoringCarrier()
  },
  methods: {
    getFactoringCarrier() {
      this.queryParams = {
        id: this.factoringId,
        params: {
          limit: 10,
          offset: 0,
        },
      }
      this.$store.dispatch(
        "factoring/GET_FACTORING_COMPANY_CARRIER_LIST",
        this.queryParams
      )
    },
    readDataFromAPI(value) {
      if (this.carriers.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.params.offset = pageNumber
        this.queryParams.params.limit = this.pageSize
        this.$store.dispatch(
          "factoring/GET_FACTORING_COMPANY_CARRIER_LIST",
          this.queryParams
        )
      }
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
