<template>
  <div class="pt-1">
    <v-row>
      <v-col cols="12" class="pt-0 pl-0 pr-0">
        <v-container
          id="tblPayment"
          fluid
          class="pt-0"
          style="max-height: 550px; position: relative; padding-bottom: 0"
        >
          <v-data-table
            id="tblData"
            :headers="headers"
            :items="payments"
            class="elevation-2"
            calculate-widths
            hide-default-footer
            fixed-header
            :items-per-page="pageSize"
            style="max-height: 550px; overflow-y: scroll"
          >
            <template v-slot:item.loadNumber="{ item }">
              <v-btn
                text
                target="_blank"
                :to="{
                  name: 'Load Detail',
                  params: { id: item.loadNumber },
                }"
              >
                {{ item.loadNumber }}
              </v-btn>
            </template>
            <template v-slot:item.carrierName="{ item }">
              <router-link
                :to="{
                  name: 'Carrier Detail',
                  params: { id: item.carrierId },
                }"
                class="name-link"
              >
                {{ item.carrierName }}
              </router-link>
            </template>
            <template v-slot:item.loadShipper="{ item }">
              <p v-if="item.loadShipper !== null" class="mb-0">
                {{ item.loadShipper }}
                <br />
                <template v-if="item.loadPickupDate !== null">
                  {{ formatDate(item.loadPickupDate) }}
                </template>
              </p>
            </template>
            <template v-slot:item.loadConsignee="{ item }">
              <p v-if="item.loadConsignee !== null" class="mb-0">
                {{ item.loadConsignee }}
                <br />
                <template v-if="item.loadDeliveryDate !== null">
                  {{ formatDate(item.loadDeliveryDate) }}
                </template>
              </p>
            </template>
            <template v-slot:item.customerPay="{ item }">
              <span v-if="item.customerPay" class="blue--text text--darken"
                >${{ item.customerPay }}</span
              >
            </template>
            <template v-slot:item.carrierPay="{ item }">
              <span v-if="item.carrierPay" class="blue--text text--darken"
                >${{ item.carrierPay }}</span
              >
            </template>
            <template v-slot:item.margin="{ item }">
              <span v-if="item.margin" class="blue--text text--darken"
                >${{ item.margin }}</span
              >
            </template>
            <template v-slot:footer>
              <v-page
                :total-row="totalRecord"
                :page-size-menu="[10, 20, 50, 100]"
                class="pt-3 pr-3 pb-3"
                @page-change="readDataFromAPI"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "Payments",
  props: {
    factoringId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "customerName", align: "center" },
        { text: "Load Number", value: "loadNumber", align: "center" },
        { text: "Status", value: "loadStatus", align: "center" },
        { text: "Carrier", value: "carrierName", align: "center" },
        { text: "Origin", value: "loadShipper", align: "center" },
        {
          text: "Destination",
          value: "loadConsignee",
          align: "center",
        },
        {
          text: "Equipment Type",
          value: "loadEquipmentType",
          align: "center",
        },
        { text: "Customer Pay", value: "customerPay", align: "center" },
        { text: "Carrier Pay", value: "carrierPay", align: "center" },
        { text: "Invoice #", value: "invoiceNumber", align: "center" },
        {
          text: "Payment Type",
          value: "paymentTypeDisplay",
          align: "center",
        },
      ],
      payments: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("factoring", {
      factoringPayments: "factoringPayments",
    }),
  },
  watch: {
    factoringPayments() {
      this.totalRecord = this.factoringPayments.count
      this.payments = this.factoringPayments.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.payments = []
    this.getFactoringPayment()
  },
  methods: {
    getFactoringPayment() {
      this.queryParams = {
        id: this.factoringId,
        params: {
          limit: 10,
          offset: 0,
        },
      }
      this.$store.dispatch(
        "factoring/GET_FACTORING_COMPANY_PAYMENTS",
        this.queryParams
      )
    },
    readDataFromAPI(value) {
      if (this.payments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.params.offset = pageNumber
        this.queryParams.params.limit = this.pageSize
        this.$store.dispatch(
          "factoring/GET_FACTORING_COMPANY_PAYMENTS",
          this.queryParams
        )
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year.toString().substr(-2)}`
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
