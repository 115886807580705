<template>
  <v-container fluid style="max-width: 1600px" class="pb-0 pt-0">
    <v-card>
      <div class="pa-2 header-banner">
        <v-row>
          <div class="col-md-9 d-flex align-center">
            <div
              class="
                white
                rounded-circle
                d-inline-block
                align-center
                text-center
                short-name
              "
            >
              <div
                class="transition-swing font-weight-bold grey--text"
                style="font-size: 48px !important"
              >
                {{ shortFactoringName }}
              </div>
            </div>
            <div class="white--text ml-4">
              <v-list-item-title class="mb-1" style="font-size: 24px">
                {{ currentFactoring.name }}
              </v-list-item-title>
              <div style="font-size: 18px">
                {{ currentFactoring.city
                }}<span v-if="currentFactoring.state">,</span>
                {{ currentFactoring.state }}
              </div>
            </div>
          </div>
        </v-row>
      </div>
      <div>
        <v-tabs v-model="currentTab" centered @change="changeTab">
          <v-tab href="#tab-1"> Details </v-tab>
          <v-tab href="#tab-2"> Carriers </v-tab>
          <v-tab href="#tab-3"> Payments </v-tab>
        </v-tabs>
      </div>
      <v-progress-linear :active="loadingIcon" color="amber" indeterminate />
    </v-card>
    <v-tabs-items v-model="currentTab" style="background: none">
      <v-tab-item value="tab-1">
        <factoring-details :factoring-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-2">
        <factoring-carriers ref="carrierTab" :factoring-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-3">
        <factoring-payments ref="paymentTab" :factoring-id="id" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import FactoringDetails from "@/views/pages/factoring-company/Details"
import FactoringCarriers from "@/views/pages/factoring-company/Carriers"
import FactoringPayments from "@/views/pages/factoring-company/Payments"
export default {
  name: "FactoringCompanyDetail",
  components: {
    FactoringDetails,
    FactoringCarriers,
    FactoringPayments,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentTab: "tab-1",
    }
  },
  computed: {
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    ...mapGetters("factoring", {
      currentFactoring: "currentFactoring",
    }),
    shortFactoringName() {
      let shortName = ""
      if (this.currentFactoring.name !== undefined) {
        shortName = this.currentFactoring.name.match(/\b(\w)/g).join("")
        shortName = shortName.charAt(0) + shortName.charAt(1)
      }
      return shortName
    },
  },
  mounted() {
    this.$store.dispatch("factoring/GET_FACTORING_COMPANY", this.id)
  },
  methods: {
    changeTab(value) {
      if (value === "tab-2") {
        if (this.$refs.carrierTab !== undefined) {
          this.$refs.carrierTab.getFactoringCarrier()
        }
      } else if (value === "tab-3") {
        if (this.$refs.paymentTab !== undefined) {
          this.$refs.paymentTab.getFactoringPayment()
        }
      }
    },
  },
}
</script>

<style scoped>
.header-banner {
  background: linear-gradient(
      0deg,
      rgba(30, 136, 229, 0.72),
      rgba(30, 136, 229, 0.72)
    ),
    #fdfdfd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0px 0px;
}
.short-name {
  border: 2px solid rgb(30, 136, 229) !important;
  width: 127px;
  height: 127px;
  line-height: 127px;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
