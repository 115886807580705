var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-1"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pl-0 pr-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pt-0",staticStyle:{"max-height":"550px","position":"relative","padding-bottom":"0"},attrs:{"id":"tblPayment","fluid":""}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"max-height":"550px","overflow-y":"scroll"},attrs:{"id":"tblData","headers":_vm.headers,"items":_vm.payments,"calculate-widths":"","hide-default-footer":"","fixed-header":"","items-per-page":_vm.pageSize},scopedSlots:_vm._u([{key:"item.loadNumber",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","target":"_blank","to":{
                name: 'Load Detail',
                params: { id: item.loadNumber },
              }}},[_vm._v(" "+_vm._s(item.loadNumber)+" ")])]}},{key:"item.carrierName",fn:function({ item }){return [_c('router-link',{staticClass:"name-link",attrs:{"to":{
                name: 'Carrier Detail',
                params: { id: item.carrierId },
              }}},[_vm._v(" "+_vm._s(item.carrierName)+" ")])]}},{key:"item.loadShipper",fn:function({ item }){return [(item.loadShipper !== null)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.loadShipper)+" "),_c('br'),(item.loadPickupDate !== null)?[_vm._v(" "+_vm._s(_vm.formatDate(item.loadPickupDate))+" ")]:_vm._e()],2):_vm._e()]}},{key:"item.loadConsignee",fn:function({ item }){return [(item.loadConsignee !== null)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.loadConsignee)+" "),_c('br'),(item.loadDeliveryDate !== null)?[_vm._v(" "+_vm._s(_vm.formatDate(item.loadDeliveryDate))+" ")]:_vm._e()],2):_vm._e()]}},{key:"item.customerPay",fn:function({ item }){return [(item.customerPay)?_c('span',{staticClass:"blue--text text--darken"},[_vm._v("$"+_vm._s(item.customerPay))]):_vm._e()]}},{key:"item.carrierPay",fn:function({ item }){return [(item.carrierPay)?_c('span',{staticClass:"blue--text text--darken"},[_vm._v("$"+_vm._s(item.carrierPay))]):_vm._e()]}},{key:"item.margin",fn:function({ item }){return [(item.margin)?_c('span',{staticClass:"blue--text text--darken"},[_vm._v("$"+_vm._s(item.margin))]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-page',{staticClass:"pt-3 pr-3 pb-3",attrs:{"total-row":_vm.totalRecord,"page-size-menu":[10, 20, 50, 100]},on:{"page-change":_vm.readDataFromAPI}})]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }