<template>
  <div>
    <v-card class="mt-0">
      <v-card-title class="pb-0">
        <v-row>
          <v-col cols="12">
            <h2>
              Basic Information
              <v-btn
                small
                text
                class="blue--text text--lighten-2 font-weight-bold float-right"
                @click="updateDetail()"
              >
                Save
              </v-btn>
            </h2>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="profile.businessName"
                type="text"
                label="Name"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <detail-location-auto-complete
                :address="fullAddress"
                :country-code="countryCodes"
                @event="setLocation"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="profile.phone"
                type="text"
                label="Phone"
                :rules="[rules.phone, rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="profile.email"
                type="text"
                label="Email"
                :rules="[rules.email, rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="profile.contactName"
                type="text"
                label="Contact Name"
                :rules="[rules.alphaNumeric, rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import DetailLocationAutoComplete from "@/views/pages/components/DetailLocationAutoComplete"

export default {
  name: "Details",
  components: {
    DetailLocationAutoComplete,
  },
  props: {
    factoringId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      profile: null,
      rules: validation.rules,
      fullAddress: null,
    }
  },
  computed: {
    ...mapGetters("generic", ["countries"]),
    ...mapGetters("factoring", ["currentFactoring"]),
    countryCodes() {
      return this.countries
        .map((country) => (country.id === "MX" ? "MEX" : country.id))
        .join(",")
    },
  },
  watch: {
    currentFactoring() {
      this.profile = this.currentFactoring
      this.fullAddress = {
        zipcode: this.profile.postalCode,
        address: this.profile.addressLine1,
        city: this.profile.city,
        state: this.profile.state,
        country: this.profile.country,
      }
    },
  },
  methods: {
    setLocation(value) {
      const {
        city = "",
        stateCode = "",
        countryCode = "",
        street = "",
        houseNumber = "",
        postalCode = "",
      } = value.address

      this.profile = {
        ...this.profile,
        addressLine1: `${houseNumber ? houseNumber + " " : ""}${street}`,
        city: city,
        state: countryCode === "USA" ? stateCode : "",
        postalCode: postalCode,
        country: countryCode,
      }
    },
    updateDetail() {
      if (this.$refs.form.validate()) {
        const payload = {
          factoringId: this.factoringId,
          data: {
            ...this.profile,
            postal_code: this.profile.postalCode,
            contact_name: this.profile.contactName,
          },
        }
        this.$store
          .dispatch("factoring/PUT_FACTORING_COMPANY", payload)
          .then(() => {
            this.$store.dispatch(
              "factoring/GET_FACTORING_COMPANY",
              this.factoringId
            )
          })
      }
    },
  },
}
</script>

<style scoped>
.header-banner {
  background: linear-gradient(
      0deg,
      rgba(30, 136, 229, 0.72),
      rgba(30, 136, 229, 0.72)
    ),
    #fdfdfd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0 0;
}

.short-name {
  border: 2px solid rgb(30, 136, 229) !important;
  width: 127px;
  height: 127px;
  line-height: 127px;
}
</style>
